/* ----------------------------------------------------------------------------- */
/* --- SETUP
/* ----------------------------------------------------------------------------- */

html {
  font-size: $base;
}

body {
  position: fixed;
  width: 100vw;
  color: $color-global;
  font-family: $font-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: $font-primary-regular;
  line-height: 1.5;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
}

button {
  cursor: pointer;
  border: 0;
  background-color: transparent;
  color: inherit;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
}

.fade-in-enter {
  opacity: 0;
}

.fade-in-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.fade-in-exit {
  opacity: 1;
}

.fade-in-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

@keyframes fading {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes loaderTurning {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

h5 {
  margin-top: 0;
  font-weight: $font-primary-medium;
  font-size: 1rem;
  display: block;
  color: $color-primary;
}

p {
  margin-top: 0;
  line-height: 1.4;
}
