/* ----------------------------------------------------------------------------- */
/* --- TYPOGRAPHY
/* ----------------------------------------------------------------------------- */

/* Headings */

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  line-height: 1.1;
  margin-bottom: 2rem;
  margin-top: 0;
  font-weight: $font-primary-bold;
}

h1,
.h1 {
  font-size: 2.25rem;
}

h2,
.h2 {
}

h3,
.h3 {
}

h4,
.h4 {
  font-size: 1.3rem;
}

h5,
.h5 {
  font-size: 1.1rem;
}

h6,
.h6 {
}

/* Paragraph */

p {
  margin-top: 0;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.lead {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-bottom: 1.5rem;
}

/* Address */
address {
  margin-bottom: 1.5rem;
  font-style: normal;
}

/* Sizes */

.text-lg {
  font-size: 1.2rem;
}

.text-sm {
  font-size: 0.9rem;
}

.text-xs {
  font-size: 0.8rem;
}

/* Colors */

@each $type, $color in $types {
  .text-#{$type} {
    color: $color;
  }
}
