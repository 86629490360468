/* ----------------------------------------------------------------------------- */
/* --- VARIABLES
/* ----------------------------------------------------------------------------- */

/* Setup  */
$base: 16px;

/* Colors */
$color-body: white;
$color-global: #1b463d;
$color-primary: #1b463d;
$color-secondary: #ade74c;
$color-tertiary: #293753;
$color-light: #f5f7fa;
$color-light-transparent: rgba(#d8dee7, 0.5);
$color-muted: #b0b7c5;
$color-muted-dark: rgba(#6b6c80, 0.3);
$color-backdrop: rgba(#868d9b, 0.4);
$color-green: #1f8c41;
$color-green-transparent: rgba($color-green, 0.2);
$color-orange: #f1ba51;
$color-red: #b5071f;
$color-red-transparent: rgba(#b5071f, 0.2);
$color-red-extra-transparent: rgba(#b5071f, 0.05);
$color-plate: #3a44a6;
$color-grey: #5b5b5b;
$color-plate-placeholder: rgba(41, 55, 83, 0.3);
$color-black: black;
$color-dark-transparent: rgba(#313c46, 0.2);

$color-line: #eff0f2;
$color-line-dark: #e1e3e8;
$color-line-extra-dark: #b0b7c5;

/* Fonts */
$font-primary: 'Roboto', sans-serif;
$font-primary-regular: 400;
$font-primary-medium: 500;
$font-primary-bold: 700;

/* Header */
$header-height: 4rem;
$header-padding: 0.75rem;
$header-vehicle-height: 3rem;

/* Steps */
$steps-height: 3rem;

/* Action Bar */
$action-bar-height: 5rem;
$action-bar-padding: 1rem;

/* Radius */
$border-radius: 0.5rem;
$border-radius-md: 0.3rem;
$border-radius-sm: 0.15rem;

/* Shadow */
$shadow: 0 0 30px 0 rgba(black, 0.1);
$shadow-primary: 0 5px 15px 0 rgba($color-primary, 0.3);

/* Input */
$input-height: 3rem;

/* Button */
$button-height: 3rem;
$button-padding: 1rem;
$button-oval-padding: 1.5rem;

/* Input */
$input-height: 3rem;
$input-padding: 1rem;

/* Breakpoints */
$breakpoint-xs: 575;
$breakpoint-sm: 767;
$breakpoint-md: 991;
$breakpoint-lg: 1199;
$breakpoint-xl: 1439;

/* Media Queries */
$mq: (
  min: (
    xs: '(min-width: #{$breakpoint-xs + 1}px)',
    sm: '(min-width: #{$breakpoint-sm + 1}px)',
    md: '(min-width: #{$breakpoint-md + 1}px)',
    lg: '(min-width: #{$breakpoint-lg + 1}px)',
    xl: '(min-width: #{$breakpoint-xl + 1}px)'
  ),
  max: (
    xs: '(max-width: #{$breakpoint-xs}px)',
    sm: '(max-width: #{$breakpoint-sm}px)',
    md: '(max-width: #{$breakpoint-md}px)',
    lg: '(max-width: #{$breakpoint-lg}px)',
    xl: '(max-width: #{$breakpoint-xl}px)'
  )
);

/* Types */
$types: (
  primary: $color-primary,
  secondary: $color-secondary,
  warning: $color-orange,
  danger: $color-red,
  success: $color-green
);
